import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';
import possessive from '@zola-helpers/client/dist/es/transformers/possessive';

type PublicV2MetadataProps = {
  wedding: WPublicWeddingView;
  pageType?: string;
  metaImageUrl?: string;
};

const PublicV2Metadata = ({
  wedding,
  metaImageUrl,
  pageType,
}: PublicV2MetadataProps): JSX.Element => {
  const router = useRouter();
  const { preview_type } = router?.query || {};
  const viewportContent =
    (preview_type as string | undefined)?.toUpperCase() === 'DESKTOP'
      ? 'width=1200'
      : 'width=device-width, initial-scale=1.0';

  const pageTitle = `${wedding.owner_first_name} ${wedding.owner_last_name} and ${
    wedding.partner_first_name
  } ${possessive(wedding.partner_last_name as string)} Wedding Website`;
  const pageDescription = `The wedding website of ${wedding.owner_first_name} ${wedding.owner_last_name} and ${wedding.partner_first_name} ${wedding.partner_last_name}`;
  const fallbackImg =
    'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-heart-black-with-padding_1200x630.png';
  const metaImageUrlFinal = metaImageUrl || fallbackImg;

  // List of page types that google should index (SEO purposes for footer links)
  const indexablePage =
    pageType === 'home' ||
    pageType === 'passcode' ||
    pageType === 'photo' ||
    pageType === 'registry';
  const isSelfCanonical = indexablePage && pageType !== 'home'; // don't self canonical the home page
  const canonicalUrl = isSelfCanonical
    ? `https://www.zola.com/wedding/${wedding.slug}/${pageType}`
    : `https://www.zola.com/wedding/${wedding.slug}`;

  // Only noindex if the wedding is not searchable
  const isNoIndex = !wedding.enable_search_engine;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      <meta name="viewport" content={viewportContent} />
      {/* Currently want to index all but may change...again */}
      {isNoIndex && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={metaImageUrlFinal} />
      <meta name="twitter:site" content="@zola" />
      <meta name="twitter:card" content="summary_large_image" />
      <link href={canonicalUrl} rel="canonical" />
    </Head>
  );
};

export default PublicV2Metadata;
